// Apollo
import * as React from "react"
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client';

// Import UIKit CSS
import './node_modules/uikit/dist/css/uikit.css'

// Import MAIN CSS
import './static/css/main.less'

// Import Jquery
import './node_modules/jquery/dist/jquery.min.js'

// Import UIKit JS
import './node_modules/uikit/dist/js/uikit-icons.min.js'
import './node_modules/uikit/dist/js/uikit.min.js'

// Import MAIN JS
//import './static/js/main.js'

const httpLink = new HttpLink({
  uri: process.env.GRAPHCMS_ENDPOINT,
  headers: {
    Authorization: `Bearer ${process.env.GRAPHCMS_TOKEN}`,
  },
  fetch: fetch,
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);

export { wrapRootElement }
